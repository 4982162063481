<template>
    <div class="admin-edit-response-team-onboarding">
        <loading-screen :is-loading="isSaving"></loading-screen>

        <!-- Page Header -->
        <div class="page-header">
            <h1 class="page-title">
                Edit Response Group
            </h1>
            <div class="actions">
                <a-button class="button-margin-left btn-rounded" type="primary" @click.prevent="nextStage">Finished Adding
                    Response Groups &rarr;</a-button>
            </div>
        </div>
        <!-- / Page Header -->

        <!-- Is Loading -->
        <div v-if="isLoading" class="loader">
            <a-spin></a-spin>
        </div>
        <!-- / Is Loading -->

        <a-tabs v-model="selectedTab" v-if="!isLoading && responseTeam">
            <a-tab-pane tab="Team Members" key="team-members">
                <response-team-members :tenant-id="tenantId" :org-id="organisation.id" :response-team="responseTeam"
                    :all-presences="membership"></response-team-members>
            </a-tab-pane>
            <a-tab-pane tab="Other Details" key="other-details">
                <!-- Form -->
                <div v-if="!isLoading && responseTeam" class="form">
                    <a-form-item label="Team Name">
                        <a-input v-model="responseTeam.displayName" size="large"></a-input>
                    </a-form-item>

                    <!-- <a-form-item label="Visibility">
                        <a-radio-group v-model="responseTeam.scope">
                            <a-radio :value="1">Shown to organisation</a-radio>
                            <a-radio :value="3">Hidden from lists</a-radio>
                        </a-radio-group>
                    </a-form-item> -->
                </div>
                <!-- / Form -->

                <a-button v-if="!isLoading && responseTeam" @click.prevent="save" class="btn-rounded save-button"
                    size="large" type="primary">Save</a-button>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
  
<script>
import { mapActions } from "vuex";
import skills from "@/api/skills";
import LoadingScreen from "@/components/LoadingScreen.vue";
import ResponseTeamMembers from "../../../views/Admin/Responders/ResponseTeams/Show/ResponseTeamMembers.vue"
export default {
    props: ['tenantId', 'organisation', 'membership', 'responseGroupId'],
    components: { LoadingScreen, ResponseTeamMembers },
    data() {
        return {
            isLoading: false,
            responseTeam: null,
            isSaving: false,

            selectedTab: "team-members"
        };
    },
    watch: {
        responseGroupId() {
            this.loadResponseTeam();
            this.selectedTab = "team-members";
        },
    },
    computed: {
        responseTeamId() {
            return this.responseGroupId;
        },
    },
    created() {
        if (!this.organisation) {
            this.$message.info("Please select an organisation");
        } else {
            this.loadResponseTeam();
        }
    },
    methods: {
        ...mapActions('onboarding', {
            setStage: 'setStage'
        }),

        nextStage() {
            this.setStage(4);
        },

        loadResponseTeam() {
            let vm = this;
            vm.responseTeam = null;
            vm.isLoading = true;
            skills
                .getSkill(this.tenantId, this.responseTeamId)
                .then((r) => {
                    vm.isLoading = false;
                    vm.responseTeam = r.data;
                })
                .catch((e) => {
                    console.log(e);
                    vm.isLoading = false;
                    vm.$message.error("Error loading response team");
                });
        },

        getValidationErrors() {
            let errors = [];
            if (this.responseTeam.displayName.trim().length == 0) {
                errors.push("Please provide a team name");
            }
            return errors;
        },

        async save() {
            let vm = this;

            // Validation
            let validationErrors = this.getValidationErrors();
            if (validationErrors.length) {
                this.$message.error(validationErrors[0]);
                return false;
            }

            vm.isSaving = true;

            skills
                .updateSkill(this.tenantId, this.responseTeam)
                .then((r) => {
                    vm.$message.success("Response team updated successfully");
                    vm.isSaving = false;
                    vm.$emit('response-group-updated', r.data);
                    // vm.loadSkillsSilently();
                    // vm.$router.push("/admin/responders/response-teams/" + r.data.id);
                })
                .catch((e) => {
                    console.log(e);
                    this.$message.error("Error updating response team");
                    vm.isSaving = false;
                });
        },
    },
};
</script>
  
<style lang="scss">
.admin-edit-response-team-onboarding {
    background: #fff;
    padding: 20px;
    border-radius: 6px;

    max-height: 500px;
    overflow-y: scroll;

    h1 {
        font-size: 17px !important;
        margin-bottom: 20px;
    }

    .loader {
        padding: 50px;
        text-align: center;
    }

    .form {
        background: #fbfbfb;
        padding: 20px;
        border-radius: 6px;
        font-weight: 500;
        border: 1px solid #fff;
    }

    .button-margin-left {
        margin-left: 10px;
    }

    .save-button {
        margin-top: 20px;
    }

    .presence-toggle {
        padding: 5px 0;
    }
}
</style>