<template>
  <div>
    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div class="loaded" v-if="!isLoading">
      <!-- No Membership -->
      <a-alert v-if="membership.length == 0" type="info" message="No team members to show"></a-alert>
      <!-- / No Membership -->

      <!-- Membership -->
      <presence-toggle v-for="presence in membership" :presence="presence" :tenant-id="tenantId" :org-id="orgId"
        :key="presence.id" @remove-presence="removePresence"></presence-toggle>
      <!-- / Membership -->

      <!-- Add Response Team -->
      <div class="add-responder">
        <!-- <add-responder-button-and-modal
          :tenant-id="tenantId"
          :org-id="orgId"
          :response-team="responseTeam"
          :membership="membership"
          :all-presences="allPresences"
          @add-presence="addPresence"
        ></add-responder-button-and-modal> -->

        <add-responders-button-and-modal :tenant-id="tenantId" :org-id="orgId" :response-team="responseTeam"
          :membership="membership" :all-presences="allPresences" @add-presences="addPresences">
        </add-responders-button-and-modal>
      </div>
      <!-- / Add Response Team -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import axios from 'axios';
const _ = require('lodash');
import skills from "../../../../../api/skills";
import PresenceToggle from "./ResponseTeamMembers/PresenceToggle.vue";
// import AddResponderButtonAndModal from "./ResponseTeamMembers/AddResponderButtonAndModal.vue";
import AddRespondersButtonAndModal from "./ResponseTeamMembers/AddRespondersButtonAndModal.vue";
import eventBus from '../../../../../event-bus';
// import presences from '../../../../../api/presences';
export default {
  props: ["tenantId", "orgId", "responseTeam", "allPresences"],
  components: { PresenceToggle, AddRespondersButtonAndModal },
  data() {
    return {
      isLoading: false,
      membership: [],

      isSaving: false
    };
  },
  created() {
    this.loadMembership();
  },
  methods: {
    loadMembership() {
      let vm = this;
      vm.isLoading = true;
      vm.membership = [];
      skills
        .getPresencesForSkill(this.tenantId, this.orgId, this.responseTeam.id)
        .then((r) => {
          vm.isLoading = false;
          vm.membership = r.data;
          eventBus.$emit('response-team-membership-loaded', {
            responseTeamId: vm.responseTeam.id,
            membershipLength: vm.membership.length
          });
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
        });
    },

    addPresence(presenceId) {
      let vm = this;
      vm.isSaving = true;
      skills.addSkillAssignment(this.tenantId, this.orgId, this.responseTeam.id, presenceId).then(() => {
        vm.isSaving = false;
        vm.$message.success('Team member added successfully');
        vm.loadMembership();
      }).catch(e => {
        console.log(e);
        vm.$message.error('Error adding team member');
        vm.isSaving = false;
      });
    },

    addPresences(presenceIds) {
      let vm = this;
      vm.isSaving = true;

      let requests = [];
      _.each(presenceIds, presenceId => {
        requests.push(
          skills.addSkillAssignment(this.tenantId, this.orgId, this.responseTeam.id, presenceId)
        );
      });

      axios.all(requests).then(() => {
        vm.isSaving = false;
        vm.$message.success('Team members added successfully');
        vm.loadMembership();
      }).catch(e => {
        console.log(e);
        vm.$message.error('Something went wrong');
        vm.isSaving = false;
        vm.loadMembership();
      });
    },

    removePresence(presenceId) {
      let vm = this;
      vm.isSaving = true;
      skills.deleteSkillAssignment(this.tenantId, this.orgId, this.responseTeam.id, presenceId).then(() => {
        vm.isSaving = false;
        vm.$message.success('Team member removed successfully');
        vm.loadMembership();
      }).catch(e => {
        console.log(e);
        vm.$message.error('Error removing team member');
        vm.isSaving = false;
      });
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.presence-toggle {
  margin-top: 10px;
}

.add-responder {
  margin-top: 25px;
}
</style>