<template>
  <div class="presence-toggle">
    <div class="left">
      <presence-thumbnail
        :presence-id="presence.id"
        :owner-id="orgId"
      ></presence-thumbnail>

      <b class="name">
        {{ presence.displayName }}
      </b>
    </div>
    <div class="right">
      <a-popconfirm
        title="Are you sure?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="$emit('remove-presence', presence.id)"
      >
        <a-button type="danger" icon="delete"></a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../../../../components/Presences/PresenceThumbnail.vue";
export default {
  props: ["presence", "tenantId", "orgId"],
  components: { PresenceThumbnail },
};
</script>

<style scoped lang="scss">
.presence-toggle {
  margin-bottom: 15px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #fff;

  display: flex;

  .name {
    font-weight: 500;
    margin-left: 20px;
  }

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>