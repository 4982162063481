<template>
  <div>
    <!-- Is Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <a-input-search
        placeholder="Search..."
        style="width: 230px; margin-bottom: 20px"
        v-model="searchQuery"
      />

      <a-alert
        v-if="responseTeamsToShow.length == 0"
        type="info"
        message="No response teams to show"
      ></a-alert>

      <a-row :gutter="10" type="flex">
        <a-col
          :span="colSpan"
          v-for="(responseTeam, i) in responseTeamsToShow"
          :key="i"
        >
          <div class="response-team-selector">
            <a-checkbox
              :disabled="disabled == true"
              :checked="isResponseTeamSelected(responseTeam)"
              @change="$emit('toggle-response-team', responseTeam.id)"
            >
              {{ responseTeam.displayName }}
            </a-checkbox>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import organisations from "../../api/organisations";
const _ = require("lodash");
export default {
  props: ["tenantId", "orgId", "selectedResponseTeamIds"],
  data() {
    return {
      isLoading: false,
      allResponseTeams: [],
      searchQuery: "",
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    colSpan() {
      if (this.windowWidth > 1000) {
        return 8;
      }
      if (this.windowWidth > 600) {
        return 12;
      }
      return 24;
    },

    responseTeamsToShow() {
      let responseTeams = this.allResponseTeams;

      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(responseTeams, (responseTeam) => {
          return responseTeam.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return responseTeams;
    },
  },
  created() {
    this.loadResponseTeams();
  },
  methods: {
    isResponseTeamSelected(responseTeam) {
      return this.selectedResponseTeamIds.includes(responseTeam.id);
    },

    loadResponseTeams() {
      let vm = this;
      vm.allResponseTeams = [];
      vm.isLoading = true;
      organisations
        .getSkillsForOrg(this.tenantId, this.orgId)
        .then((r) => {
          vm.isLoading = false;
          vm.allResponseTeams = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading all response teams");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  padding-top: 20px;
  padding-bottom: 20px;
}

.response-team-selector {
  margin-bottom: 15px;
}
</style>