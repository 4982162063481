<template>
    <div class="onboarding">
        <!-- X icon -->
        <div class="x-icon-wrapper" v-if="orgsToShow.length < organisations.length">
            <a-tooltip placement="left">
                <template slot="title">
                    Exit onboarding
                </template>
                <a-button type="primary" shape="circle" size="large" @click="$router.push('/dashboard')">
                    <a-icon type="close" />
                </a-button>
            </a-tooltip>
        </div>
        <!-- / X icon -->

        <div class="onboarding-inner" v-if="organisation">

            <!-- Title -->
            <div class="title">
                <h1>Onboarding {{ organisation['displayName'] }}</h1>
                <div class="org-selector-outter-wrapper" v-if="orgsToShow.length > 1">
                    <onboarding-organisational-selector></onboarding-organisational-selector>
                </div>
            </div>
            <!-- / Title -->

            <!-- Steps -->
            <div class="steps-wrapper">
                <a-steps progress-dot size="small" :current="stage" @change="onStepClicked">
                    <a-step title="Technical Setup" />
                    <a-step title="Import Users" />
                    <a-step title="Users" />
                    <a-step title="Response Groups" />
                    <a-step title="Emergency Types" />
                    <a-step title="Incident Types" />
                    <a-step title="Review" />
                </a-steps>
            </div>
            <!-- / Steps -->

            <!-- Technical Setup -->
            <div v-if="stage == 0" class="stage-wrapper">
                <onboarding-technical-setup @next-stage="onStepClicked(1)"></onboarding-technical-setup>
            </div>
            <!-- / Technical Setup -->

            <!-- Import Users -->
            <div v-if="stage == 1" class="stage-wrapper">
                <onboarding-import-users @next-stage="onStepClicked(2)"></onboarding-import-users>
            </div>
            <!-- / Import Users -->

            <!-- Users -->
            <div v-if="stage == 2" class="stage-wrapper">
                <onboarding-users></onboarding-users>
            </div>
            <!-- / Users -->

            <!-- Response Groups -->
            <div v-if="stage == 3" class="stage-wrapper">
                <onboarding-response-groups></onboarding-response-groups>
            </div>
            <!-- / Response Groups -->

            <!-- Emergency Types-->
            <div v-if="stage === 4" class="stage-wrapper">
                <onboarding-emergency-types></onboarding-emergency-types>
            </div>
            <!-- / Emergency Types-->

            <!-- Incident Types -->
            <div v-if="stage === 5" class="stage-wrapper">
                <onboarding-incident-types></onboarding-incident-types>
            </div>
            <!-- / Incident Types -->

            <!-- Review -->
            <div v-if="stage === 6" class="stage-wrapper">
                <onboarding-review></onboarding-review>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import OnboardingTechnicalSetup from '../components/Onboarding/OnboardingTechnicalSetup.vue';
import OnboardingImportUsers from '../components/Onboarding/OnboardingImportUsers.vue';
import OnboardingUsers from '../components/Onboarding/OnboardingUsers.vue';
import OnboardingEmergencyTypes from '../components/Onboarding/OnboardingEmergencyTypes.vue';
import OnboardingResponseGroups from '../components/Onboarding/OnboardingResponseGroups.vue';
import OnboardingIncidentTypes from '../components/Onboarding/OnboardingIncidentTypes.vue';
import OnboardingOrganisationalSelector from '../components/Onboarding/OnboardingOrganisationalSelector.vue';
import OnboardingReview from '../components/Onboarding/OnboardingReview.vue';

export default {
    components: { OnboardingTechnicalSetup, OnboardingImportUsers, OnboardingUsers, OnboardingEmergencyTypes, OnboardingIncidentTypes, OnboardingResponseGroups, OnboardingOrganisationalSelector, OnboardingReview },

    created() {
        if (this.orgsToShow && this.orgsToShow.length) {
            if (
                this.lastOrgId &&
                _.find(this.orgsToShow, { id: this.lastOrgId })
            ) {
                this.startOnboardingForOrganisation(_.find(this.orgsToShow, { id: this.lastOrgId }));
            } else {
                this.startOnboardingForOrganisation(this.orgsToShow[0]);
            }
        } else {
            // this.$message.info('No organisations need onboarding');
            this.$router.push('/dashboard');
        }
    },

    methods: {
        ...mapActions('onboarding', {
            startOnboardingForOrganisation: 'startOnboardingForOrganisation',
            setStage: 'setStage'
        }),

        onStepClicked(step) {
            this.setStage(step);
        }
    },

    computed: {
        ...mapGetters('missionControl', {
            organisationsIAmAnAdminForWithIncompleteOnboarding: 'organisationsIAmAnAdminForWithIncompleteOnboarding',
            organisations: 'organisations'
        }),

        ...mapGetters("subjectPreferences", {
            lastOrgId: "lastOrgId",
        }),

        ...mapGetters('onboarding', {
            organisation: 'organisation',
            stage: 'stage',
            idsOfOrganisationsToHide: "idsOfOrganisationsToHide"
        }),

        orgsToShow() {
            return _.filter(this.organisationsIAmAnAdminForWithIncompleteOnboarding, (org) => {
                return !_.includes(this.idsOfOrganisationsToHide, org.id)
            });
        },
    },

    data() {
        return {
        }
    },
}
</script>

<style lang="scss">
.onboarding {
    font-family: "Quicksand";
    position: relative;

    .x-icon-wrapper {
        position: absolute;
        top: -25px;
        right: -35px;
        padding: 20px;
        z-index: 1000;

        .anticon {
            // font-size: 20px;
        }
    }

    .org-selector-outter-wrapper {
        margin-top: 25px;
    }

    .onboarding-inner {
        display: block;
        position: relative;
        // max-width: 1200px;

        @media screen and (min-width: 1200px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        margin-left: auto;
        margin-right: auto;
        padding-bottom: 50px;

        .title {
            text-align: center;

            h1 {
                font-size: 23px;
            }
        }

        .steps-wrapper {
            margin-top: 35px;
            margin-left: auto;
            margin-right: auto;

            .ant-steps-small .ant-steps-item-title {
                font-size: 15px;
            }

            .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
                background: #3A0BA3 !important;
            }

            .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
                background: #3A0BA3 !important;
            }

            .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
                background-color: #3A0BA3 !important;
            }

            .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
                background: #3A0BA3 !important;
            }
        }

        .stage-wrapper {
            margin-top: 45px;
        }

        // .stage-wrapper {
        //     margin-top: 35px;
        //     text-align: center;
        //     padding: 20px;
        //     width: 100%;
        //     background: #fff;
        //     border-radius: 5px;
        // }
    }
}
</style>