<template>
  <div class="onboarding-create-incident-type">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        Create Incident Type
      </h1>
      <div class="actions">
        <a-button class="button-margin-left btn-rounded" type="primary" @click.prevent="nextStage">Finished Adding
          Incident Types &rarr;</a-button>
      </div>
    </div>
    <!-- / Page Header -->


    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <a-form-item label="Incident Type Name">
        <a-input size="large" v-model="incidentType.displayName"></a-input>
      </a-form-item>

      <!-- <a-form-item label="Visibility">
        <a-radio-group v-model="incidentType.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item> -->

      <!-- <a-form-item label="Display Order">
        <a-radio-group v-model="incidentType.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item> -->

      <!-- Row -->
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item label="Assign To This Response Team">
            <a-select style="width: 100%" show-search option-filter-prop="children" :filter-option="filterOption"
              size="large" v-model="incidentType.skillId">
              <a-select-option :value="skill.id" v-for="skill in skills" :key="skill.id">
                {{ skill.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Group This Type With">
            <a-select style="width: 100%" show-search option-filter-prop="children" :filter-option="filterOption"
              size="large" v-model="incidentType.incidentTypeGroupId">
              <a-select-option :value="incidentTypeGroup.id" v-for="incidentTypeGroup in incidentTypeGroups"
                :key="incidentTypeGroup.id">
                {{ incidentTypeGroup.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- / Row -->
    </div>
    <!-- / Form -->

    <!-- Advanced Settings Toggle -->
    <!-- <div v-if="!isLoading" @click.prevent="showAdvancedSettings = !showAdvancedSettings" class="advanced-settings-toggle">
      {{ showAdvancedSettings ? "Hide" : "Show" }} advanced settings
      <a-icon v-if="!showAdvancedSettings" type="down" style="margin-left: 10px"></a-icon>
      <a-icon v-if="showAdvancedSettings" type="up" style="margin-left: 10px"></a-icon>
    </div> -->
    <!-- / Advanced Settings Toggle -->

    <!-- Advanced Settings -->
    <div v-if="showAdvancedSettings" class="form advanced-settings-form">
      <h3>Advanced Settings</h3>

      <a-form-item label="Override Response Team" style="margin-bottom: 35px">
        <a-select style="width: 100%" show-search option-filter-prop="children" :filter-option="filterOption" size="large"
          v-model="incidentType.skillId" :disabled="flags.customRoute">
          <a-select-option :value="null">
            Use default team {{ defaultSosTeam ? '(' + defaultSosTeam.displayName + ')' : '' }}
          </a-select-option>
          <a-select-option :value="skill.id" v-for="skill in skills" :key="skill.id">
            {{ skill.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-row :gutter="20">
        <a-col :span="8">
          <!-- Longer alert sounds -->
          <!-- <a-form-item label="Use Longer Alert Sounds"> -->
          <a-checkbox v-model="flags.longerAlertSounds">
            User longer alert sounds
          </a-checkbox>
          <!-- </a-form-item> -->
          <!-- / Longer alert sounds -->
        </a-col>
        <a-col :span="8">
          <!-- Show first responder in timeline -->
          <!-- <a-form-item label="Show First Responder In Timeline"> -->
          <div class="checkbox-wrapper">
            <a-checkbox v-model="flags.showFirstResponse">
              Show first responder in timeline
            </a-checkbox>
          </div>
          <!-- </a-form-item> -->
          <!-- / Show first responder in timeline -->
        </a-col>
        <a-col :span="8">
          <!-- First arrival is first responder -->
          <!-- <a-form-item label="First Arrival Is First Responder"> -->
          <div class="checkbox-wrapper">
            <a-checkbox v-model="flags.arrivingIsFirstResponse">
              First arrival is first responder
            </a-checkbox>
          </div>
          <!-- </a-form-item> -->
          <!-- / First arrival is first responder -->
        </a-col>
      </a-row>

      <!-- Sliders -->
      <div class="slider-wrapper">
        <a-form-item :label="`Repeat Alert Every (${formatter(
          incidentType.repeatInterval,
          'Off'
        )})`">
          <a-slider v-model="incidentType.repeatInterval" :tip-formatter="(value) => formatter(value, 'Off')" :min="0"
            :max="300" :step="30" />
        </a-form-item>

        <a-form-item :label="`Stop Repeating After (${formatter(
          incidentType.repeatUntil,
          'Off'
        )})`">
          <a-slider v-model="incidentType.repeatUntil" :tip-formatter="(value) => formatter(value, 'Off')" :min="0"
            :max="600" :step="30" />
        </a-form-item>

        <a-form-item :label="`Start Hunting After (${formatter(
          incidentType.huntAfter,
          'Off'
        )})`">
          <a-slider v-model="incidentType.huntAfter" :tip-formatter="(value) => formatter(value, 'Off')" :min="0"
            :max="300" :step="30" />
        </a-form-item>
      </div>
      <!-- / Sliders -->
    </div>
    <!-- / Advanced Settings -->

    <!-- Custom Routing Toggle -->
    <!-- <div v-if="!isLoading" @click.prevent="showCustomRouting = !showCustomRouting" class="custom-routing-toggle">
      {{ showCustomRouting ? "Hide" : "Show" }} custom routing
      <a-icon v-if="!showCustomRouting" type="down" style="margin-left: 10px"></a-icon>
      <a-icon v-if="showCustomRouting" type="up" style="margin-left: 10px"></a-icon>
    </div> -->
    <!-- / Custom Routing Toggle -->

    <!-- Custom Routing -->
    <div v-if="showCustomRouting" class="form custom-routing-form">
      <h3>Custom Routing</h3>
      <a-row :gutter="20">
        <a-col :span="12">
          <!-- Custom hunt path -->
          <!-- <a-form-item label="Custom Hunt Path"> -->
          <a-checkbox v-model="flags.customRoute">
            Custom Hunt Path
          </a-checkbox>
          <!-- </a-form-item> -->
          <!-- / Custom hunt path -->
        </a-col>
        <a-col v-if="flags.customRoute" :span="12">
          <!-- Alert unavailable members of final team as last resort -->
          <!-- <a-form-item label="Alert unavailable members of final team as last resort"> -->
          <a-checkbox v-model="flags.broadcastOnLast">
            Alert unavailable members of final team as last resort
          </a-checkbox>
          <!-- </a-form-item> -->
          <!-- / Alert unavailable members of final team as last resort -->
        </a-col>
      </a-row>

      <div v-if="flags.customRoute" class="custom-hunt-path-editor-wrapper">
        <custom-hunt-path-editor :skills="skills" :initial-route="incidentType.customRoute"
          @set-custom-route="setCustomRoute">
        </custom-hunt-path-editor>
      </div>
    </div>
    <!-- / Custom Routing -->

    <a-button v-if="!isLoading" @click.prevent="save" class="btn-rounded save-button" size="large"
      type="primary">Save</a-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingScreen from "../../LoadingScreen.vue";
import guidanceHelpers from "../../../helpers/guidance";
import incidentTypes from "../../../api/incident-types";
import CustomHuntPathEditor from "../../Admin/CustomHuntPathEditor.vue";
const _ = require("lodash");

const NONE = 0;
const SINGLE_RESPONDER = 1;
const NO_SOS_FALLBACK = 2;
const AUTO_ESCALATE = 4;
const LONGER_IDENTS = 8;
const CUSTOM_ROUTE = 16;
const NO_BROADCAST_ON_LAST = 32;
const REQUIRE_ACTIVE_FIRST_RESPONSE = 64;
const SHOW_FIRST_RESPONSE = 128;

export default {
  props: ['skills', 'organisation', 'incidentTypes', 'tenantId'],
  components: { LoadingScreen, CustomHuntPathEditor },
  data() {
    return {
      isLoadingAllIncidentTypes: false,

      isSaving: false,

      incidentType: {
        displayName: "",
        scope: 1,
        rank: 0,
        skillId: null,
        incidentTypeGroupId: null,

        repeatInterval: 60,
        repeatUntil: 300,
        huntAfter: 90,
      },

      showAdvancedSettings: false,
      showCustomRouting: false,

      flags: {
        autoEscalate: false,
        longerAlertSounds: false,
        customRoute: false,
        broadcastOnLast: true,
        showFirstResponse: false,
        requireActiveFirstResponse: false,
        assignOnlyOneResponder: false,
        sosFallback: true,
        arrivingIsFirstResponse: true,
      },
    };
  },
  created() {
    if (!this.organisation) {
      this.$message.info("Please select an organisation");
    }
    // this.loadAllIncidentTypes();
  },
  methods: {
    ...mapActions('onboarding', {
      setStage: 'setStage'
    }),

    loadAllIncidentTypes() {
      let vm = this;
      vm.isLoadingAllIncidentTypes = true;
      vm.incidentTypes = [];
      incidentTypes
        .getIncidentTypes(this.tenantId, this.organisation.id)
        .then((r) => {
          vm.isLoadingAllIncidentTypes = false;
          vm.incidentTypes = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingAllIncidentTypes = false;
          vm.$message.error("Error loading all incident types");
        });
    },

    nextStage() {
      this.setStage(6);
    },

    setCustomRoute(skillIds) {
      let withoutNulls = _.without(skillIds, null);
      this.incidentType.customRoute = _.map(withoutNulls, (skillId) => {
        return {
          skillId: skillId,
        };
      });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    getValidationErrors() {
      let errors = [];
      if (this.incidentType.displayName.trim().length == 0) {
        errors.push("Please provide a name");
      }
      return errors;
    },

    formatter(value, zeroLabel = "Never") {
      if (value == 0) {
        return zeroLabel;
      }
      let s = value;
      return (
        (s - (s %= 60)) / 60 + (9 < s ? "m" : "m") + (s ? " " + s + "s" : "")
      );
      // return `${value} ${value == 1 ? "hr" : "hrs"}`;
    },

    determineNewFlags() {
      return (
        // Straight mapped flags
        (this.flags.assignOnlyOneResponder ? SINGLE_RESPONDER : NONE) |
        (this.flags.autoEscalate ? AUTO_ESCALATE : NONE) |
        (this.flags.longerAlertSounds ? LONGER_IDENTS : NONE) |
        (this.flags.customRoute ? CUSTOM_ROUTE : NONE) |
        (this.flags.showFirstResponse ? SHOW_FIRST_RESPONSE : NONE) |
        // Reverse mapped flags
        (this.flags.sosFallback ? NONE : NO_SOS_FALLBACK) |
        (this.flags.broadcastOnLast ? NONE : NO_BROADCAST_ON_LAST) |
        (this.flags.arrivingIsFirstResponse
          ? NONE
          : REQUIRE_ACTIVE_FIRST_RESPONSE)
      );
    },

    save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      let newFlags = this.determineNewFlags();

      let params = {
        ...this.incidentType,
        ownerId: this.organisation.id,
        purpose: 2,
        flags: newFlags,
        assignRule: 1,
      };

      vm.isSaving = true;
      incidentTypes
        .addIncidentType(this.tenantId, params)
        .then((r) => {
          vm.isSaving = false;
          vm.$message.success("Incident type created successfully");
          vm.$emit('incident-type-created', r.data);
          // vm.$router.push("/admin/settings/emergency-types");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error updating incident type");
        });
    },
  },
  computed: {
    ranks() {
      return guidanceHelpers.listRankOptions();
    },

    isLoading() {
      return this.isLoadingAllIncidentTypes;
    },

    incidentTypeGroups() {
      return _.filter(this.incidentTypes, (incidentType) => {
        return incidentType.incidentTypeGroupId == undefined;
      });
    },

    defaultSosTeamId() {
      if (
        this.organisation &&
        this.organisation["content"] &&
        this.organisation["content"]["$v"]
      ) {
        return this.organisation["content"]["$v"]["sosTeamId"];
      }
      return null;
    },

    defaultSosTeam() {
      return _.find(this.skills, { id: this.defaultSosTeamId });
    },
  },
};
</script>

<style scoped lang="scss">
.onboarding-create-incident-type {
  background: #fff;
  padding: 20px;
  border-radius: 6px;

  max-height: 500px;
  overflow-y: scroll;

  h1 {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .top-row {
    display: flex;

    .left {
      flex-shrink: 1;
    }

    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fbfbfb;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    h3 {
      padding-bottom: 20px;
    }
  }

  .advanced-settings-form,
  .custom-routing-form {
    padding: 30px;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 25px;
  }

  .advanced-settings-toggle,
  .custom-routing-toggle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    background: rgba(81, 85, 234, 0.2);
    cursor: pointer;
    border-radius: 6px;
    // color: #fff;
    padding: 9px;
    font-weight: 500;
  }

  .custom-hunt-path-editor-wrapper {
    margin-top: 25px;
  }

  .checkbox-wrapper {
    margin-bottom: 25px;
  }

  .slider-wrapper {
    .ant-slider {
      margin-top: 40px !important;
    }
  }
}
</style>