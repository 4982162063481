<template>
    <div class="incident-type-sidebar">

        <div class="incident-type" @click.prevent="selectIncidentType('create')"
            :class="{ 'selected': ('create' == selectedIncidentTypeId) }">
            <span class="display-name">+ Add New Type</span>
        </div>

        <div class="incident-type" @click.prevent="selectIncidentType(incidentType)"
            :class="{ 'selected': (incidentType.id == selectedIncidentTypeId) }"
            v-for="(incidentType, i) in incidentTypesToShow" :key="`incident-type-${i}`">
            <span class="display-name">{{ incidentType.displayName }}</span>
        </div>

        <!-- No incident types -->
        <div v-if="!incidentTypesToShow.length" class="incident-type no-hover">
            <span class="display-name">No Incident Types</span>
        </div>

    </div>
</template>

<script>
export default {
    props: ['incidentTypes', 'skills', 'selectedIncidentType'],

    methods: {
        selectIncidentType(incidentType) {
            this.$emit('select-incident-type', incidentType);
        }
    },

    computed: {
        selectedIncidentTypeId() {
            if (this.selectedIncidentType && this.selectedIncidentType.id) {
                return this.selectedIncidentType.id;
            }

            if (this.selectedIncidentType == 'create') {
                return 'create';
            }

            return null;
        },

        incidentTypesToShow() {
            return this.incidentTypes;
        }
    }
}
</script>

<style scoped lang="scss">
.incident-type-sidebar {
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    max-height: 500px;
    overflow-y: scroll;

    .incident-type {
        padding: 13px;
        border-radius: 5px;
        margin-bottom: 5px;
        border: 1px solid #fff;

        &:not(.no-hover):hover {
            color: #5155ea;
            border: 1px solid #5155ea;
            cursor: pointer;
        }

        &.selected {
            // background: #5155ea;
            background: rgba(81, 85, 234, 0.1);
            // border: 1px solid rgba(81, 85, 234, 0.1) !important;
            // color: #fff;
            // border: 1px solid #5155ea; 
        }
    }
}
</style>