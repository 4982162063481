<template>
    <div>
        <a-alert class="import-users-alert"
            message="To grant access into teamSOS for incident management, we provide two convenient options when adding users. You can choose to take advantage of our CSV import feature, allowing you to upload a list of users efficiently. Alternatively, you can opt to manually add users one by one using the provided options below. This comprehensive approach ensures that both users seeking assistance and those responding to incidents are smoothly integrated into our system."></a-alert>

        <a-row class="onboarding-options-row" :gutter="50">
            <a-col :span="12">
                <div class="option-card" @click.prevent="openCsvImportModal">
                    <div class="inside">
                        <a-icon type="cloud-upload" />
                        <h3>Import Users From CSV</h3>
                    </div>
                </div>
                <csv-import-modal ref="csvImportModal" @import-complete="goToNextStage"></csv-import-modal>
            </a-col>
            <a-col :span="12">
                <div class="option-card" @click.prevent="goToNextStage">
                    <div class="inside">
                        <a-icon type="usergroup-add" />
                        <h3>Add Users Manually</h3>
                    </div>
                </div>
            </a-col>
            <!-- <a-col :span="8">
                <div class="option-card disabled">
                    <div class="inside">
                        <a-icon type="import" />
                        <h3>Import Users From Wonde (Coming Soon)</h3>
                    </div>
                </div>
            </a-col> -->
        </a-row>
    </div>
</template>

<script>
import CsvImportModal from './ImportUsers/CsvImportModal.vue';
export default {
    components: { CsvImportModal },
    methods: {
        openCsvImportModal() {
            this.$refs.csvImportModal.open();
        },

        goToNextStage() {
            this.$emit('next-stage');
        }
    }
}
</script>

<style scoped lang="scss">
.onboarding-options-row {
    margin-top: 30px;
}

.import-users-alert {
    text-align: center;
}

.option-card {
    background: #fff;
    border-radius: 6px;
    padding: 30px;
    text-align: center;
    min-height: 230px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover:not(.disabled) {
        // border: 1px solid #1890ff;
        border: 1px solid #3A0BA3;

        i,
        h3 {
            color: #3A0BA3;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    i {
        font-size: 45px;
        margin-bottom: 30px;
    }

    h3 {
        font-size: 22px;
        margin-bottom: 0 !important;
    }
}
</style>