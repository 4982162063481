<template>
    <div>
        <!-- Is Loading -->
        <div class="loader" v-if="isLoading">
            <a-spin></a-spin>
        </div>
        <!-- / Is Loading -->

        <!-- Loaded -->
        <a-row v-if="!isLoading" :gutter="40" type="flex">
            <a-col :span="6">
                <users-sidebar :users="usersToShow" :selected-user="selectedUser" @select-user="selectUser"></users-sidebar>
            </a-col>
            <a-col :span="18">
                <edit-user v-if="selectedUser && selectedUser !== 'create'" :tenant-id="tenantId" :user-id="selectedUser.id"
                    :organisation="organisation" @user-updated="updateUserInline"
                    @user-deleted="loadUsersAndResetCreateForm"></edit-user>

                <create-user v-if="selectedUser && selectedUser == 'create'" :tenant-id="tenantId"
                @user-created="handleUserCreated" :key="createUserRefreshKey"
                    :organisation="organisation"></create-user>
            </a-col>
        </a-row>
        <!-- / Loaded -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import UsersSidebar from './Users/UsersSidebar.vue';
import CreateUser from './Users/CreateUser.vue';
import EditUser from './Users/EditUser.vue';
import organisations from '../../api/organisations';

export default {
    data() {
        return {
            isLoadingUsers: false,
            users: [],

            selectedUser: null,

            createUserRefreshKey: 1
        }
    },

    watch: {
        organisation() {
            this.isLoadingUsers = false;
            this.users = [];
            this.selectedUser = null;
            this.createUserRefreshKey = 1;
            this.loadUsersAndResetCreateForm();
        }
    },

    components: { UsersSidebar, EditUser, CreateUser },

    created() {
        // this.loadUsersAndSelectFirst();
        this.loadUsersAndCreate();
    },

    computed: {
        ...mapGetters('onboarding', {
            tenantId: 'tenantId',
            organisation: 'organisation'
        }),

        isLoading() {
            return this.isLoadingUsers;
        },

        usersToShow() {
            return this.users;
        }
    },

    methods: {
        ...mapActions('onboarding', {
            updateOnboardingMembership: 'updateOnboardingMembership'
        }),
        
        loadUsers() {
            let vm = this;
            vm.isLoadingUsers = true;
            return organisations.getMembershipForOrg(vm.tenantId, vm.organisation.id).then(response => {
                vm.users = response.data;
                vm.isLoadingUsers = false;
                vm.updateOnboardingMembership(response.data);
            }).catch(e => {
                vm.isLoadingUsers = false;
                console.log(e);
            })
        },

        selectUser(user) {
            let vm = this;
            vm.selectedUser = user;
        },

        handleUserCreated() {
            let vm = this;
            vm.createUserRefreshKey += 1;
            vm.loadUsers().then(() => {
                vm.selectedUser = 'create'; 
            }).catch(e => {
                console.log(e);
            });
        },

        loadUsersAndResetCreateForm() {
            let vm = this;
            vm.createUserRefreshKey += 1;
            vm.loadUsers().then(() => {
                // if (vm.users.length) {
                //     vm.selectedUser = _.first(vm.usersToShow);
                // }
                vm.selectedUser = 'create';
            }).catch(e => {
                console.log(e);
            });
        },

        loadUsersAndSelectFirst() {
            let vm = this;
            vm.loadUsers().then(() => {
                if (vm.users.length) {
                    vm.selectedUser = _.first(vm.usersToShow);
                }
            }).catch(e => {
                console.log(e);
            });
        },

        loadUsersAndCreate() {
            let vm = this;
            vm.loadUsers().then(() => {
                // if (vm.users.length) {
                //     vm.selectedUser = _.first(vm.usersToShow);
                // }
                vm.selectedUser = 'create';
            }).catch(e => {
                console.log(e);
            });
        },

        updateUserInline(user) {
            let vm = this;
            let index = _.findIndex(vm.users, { id: user.id });
            if (index > -1) {
                vm.users.splice(index, 1, user);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.loader {
    padding-top: 20px;
    text-align: center;
}
</style>