<template>
    <div class="response-group-sidebar">

        <div class="response-group" @click.prevent="selectResponseGroup('create')"
            :class="{ 'selected': ('create' == selectedResponseGroupId) }">
            <div class="left">
                <span class="display-name">+ Add New Group</span>
            </div>
        </div>

        <div class="response-group" @click.prevent="selectResponseGroup(responseGroup)"
            :class="{ 'selected': (responseGroup.id == selectedResponseGroupId) }"
            v-for="(responseGroup, i) in responseGroupsToShow" :key="`response-group-${i}`">
            <div class="left">
                <span class="display-name">{{ responseGroup.displayName }}</span>
            </div>
            <div class="right" v-if="responseGroupMembershipCounts">

                <a-tooltip placement="right">
                    <template slot="title">
                        <span>Please add some members to this response group.</span>
                    </template>

                    <a-icon class="exclamation-icon"
                        v-if="responseGroupMembershipCounts[responseGroup.id] !== undefined && responseGroupMembershipCounts[responseGroup.id] === 0"
                        type="exclamation-circle" />
                </a-tooltip>

            </div>
        </div>

        <!-- No response groups -->
        <div v-if="!responseGroupsToShow.length" class="response-group no-hover">
            <div class="left">
                <span class="display-name">No Response Groups</span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['responseGroups', 'responseGroupMembershipCounts', 'selectedResponseGroup'],

    data() {
        return {}
    },

    methods: {
        selectResponseGroup(responseGroup) {
            this.$emit('select-response-group', responseGroup);
        }
    },

    computed: {
        selectedResponseGroupId() {
            if (this.selectedResponseGroup && this.selectedResponseGroup.id) {
                return this.selectedResponseGroup.id;
            }

            if (this.selectedResponseGroup == 'create') {
                return 'create';
            }

            return null;
        },

        responseGroupsToShow() {
            // put response groups with a response group membership count of 0 at the top
            if (this.responseGroupMembershipCounts) {
                return [...this.responseGroups].sort((a, b) => {
                    if (this.responseGroupMembershipCounts[a.id] === 0) {
                        return -1;
                    }

                    if (this.responseGroupMembershipCounts[b.id] === 0) {
                        return 1;
                    }

                    return 0;
                });
            }

            return this.responseGroups;
        }
    }
}
</script>

<style scoped lang="scss">
.response-group-sidebar {
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    max-height: 500px;
    overflow-y: scroll;

    .response-group {
        padding: 13px;
        border-radius: 5px;
        margin-bottom: 5px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;

        .exclamation-icon {
            color: #ff4d4f;
            font-size: 17px;
        }

        .left {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }

        .right {
            display: flex;
            align-items: center;
            flex-shrink: 1;
            padding-left: 5px;
        }

        &:not(.no-hover):hover {
            color: #5155ea;
            border: 1px solid #5155ea;
            cursor: pointer;
        }

        &.selected {
            // background: #5155ea;
            background: rgba(81, 85, 234, 0.1);
            // border: 1px solid rgba(81, 85, 234, 0.1) !important;
            // color: #fff;
            // border: 1px solid #5155ea; 
        }
    }
}
</style>