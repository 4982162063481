<template>
  <div class="admin-edit-user">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <!-- <h1>Edit User</h1> -->
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        Edit User
      </h1>
      <div class="actions">
        <a-button class="button-margin-left btn-rounded" type="primary" @click.prevent="nextStage">Finished Adding Users
          &rarr;</a-button>
      </div>
    </div>
    <!-- / Page Header -->
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <!-- Top Row -->
      <div class="top-row">
        <div class="left" :class="{
          'has-custom-avatar': !shouldRemoveExistingAvatar,
        }">
          <a-form-item label="Photo">
            <div v-if="!shouldRemoveExistingAvatar && !fileList.length" class="custom-avatar">
              <div class="left">
                <presence-thumbnail size="large" :ownerId="organisation.id" :presenceId="user.id"></presence-thumbnail>
              </div>
              <div class="right">
                <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No"
                  @confirm="shouldRemoveExistingAvatar = true">
                  <a href="#">Remove Icon</a>
                </a-popconfirm>
              </div>
            </div>

            <a-upload :multiple="false" :file-list="fileList" :before-upload="beforeUpload" :remove="handleRemove">
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to
                {{
                  !shouldRemoveExistingAvatar || fileList.length
                  ? "change"
                  : "upload"
                }}
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-item label="First Name">
                <a-input size="large" v-model="user.firstname"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Surname">
                <a-input size="large" v-model="user.surname"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- / Top Row -->

      <!-- Display Name -->
      <a-form-item label="Display Name">
        <a-input size="large" v-model="user.displayName"></a-input>
      </a-form-item>
      <!-- / Display Name -->

      <!-- Email -->
      <a-form-item label="Email">
        <a-input size="large" v-model="user.email"></a-input>
      </a-form-item>
      <!-- / Email -->

      <!-- Alt Email -->
      <!-- <a-form-item label="Alternative Email (Where Differs To Username)">
        <a-input size="large" v-model="user.altEmail"></a-input>
      </a-form-item> -->
      <!-- / Alt Email -->

      <a-form-item style="margin-top: 15px; margin-bottom: 0" label="Permission"></a-form-item>

      <onboarding-user-permissions-selector v-if="user.permissions !== undefined" :permissions="user.permissions"
        @set-permissions="setPermissions"></onboarding-user-permissions-selector>

      <!-- <a-form-item style="margin-top: 25px; margin-bottom: 0;" label="Access Expires">
        <a-date-picker @change="forceUpdate" show-time size="large" style="width: 100%" v-model="user.accessExpires" />
      </a-form-item> -->
    </div>
    <!-- / Form -->

    <a-button @click.prevent="save" class="btn-rounded save-button" size="large" type="primary">Save</a-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import presences from "../../../api/presences";
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
import OnboardingUserPermissionsSelector from "../../../components/Admin/OnboardingUserPermissionSelector.vue";
export default {
  props: ['organisation', 'userId', 'tenantId'],
  data() {
    return {
      isSaving: false,
      isLoading: false,
      user: null,
      shouldRemoveExistingAvatar: false,
      fileList: [],
    };
  },
  components: { LoadingScreen, PresenceThumbnail, OnboardingUserPermissionsSelector },
  created() {
    this.loadUser();
  },
  watch: {
    userId() {
      this.loadUser();
    },
  },
  methods: {
    ...mapActions('temporaryImages', {
      removeFromCache: 'removeFromCache'
    }),

    ...mapActions('onboarding', {
      setStage: 'setStage'
    }),

    nextStage() {
      this.setStage(3);
    },

    goBack() {
      this.$router.push("/admin/users/manage/" + this.userId);
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    forceUpdate() {
      this.$forceUpdate();
    },

    loadUser() {
      let vm = this;
      vm.isLoading = true;
      presences
        .getPresence(vm.tenantId, vm.userId)
        .then((r) => {
          vm.isLoading = false;
          vm.user = r.data;
          if (vm.user.deprecatedInstant) {
            vm.user.accessExpires = window.moment.utc(vm.user.deprecatedMoment);
            vm.$forceUpdate();
          }
          if (vm.user.permissions == undefined) {
            vm.user.permissions = 0;
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error editing user");
          // vm.$router.push("/admin/users/manage/" + vm.userId);
        });
    },

    setPermissions(permissions) {
      this.user.permissions = permissions;
      this.$forceUpdate();
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    getValidationErrors() {
      let errors = [];
      if (this.user.firstname.trim().length == 0) {
        errors.push("Please provide a first name");
      }
      if (this.user.surname.trim().length == 0) {
        errors.push("Please provide a surname");
      }
      if (this.user.email.trim().length == 0) {
        errors.push("Please provide an email");
      }
      if (!this.validateEmail(this.user.email)) {
        errors.push(this.user.email + " is not a valid email");
      }
      if (
        this.user.altEmail
        && this.user.altEmail.trim().length &&
        !this.validateEmail(this.user.altEmail)
      ) {
        errors.push(this.user.altEmail + " is not a valid email");
      }

      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = { ...this.user };
      if (this.user.accessExpires == null) {
        params.deprecatedInstant = null;
      } else {
        params.deprecatedInstant = window.moment(this.user.accessExpires).toISOString();
      }

      try {
        let updatedPresence = await presences.updatePresence(this.tenantId, params).then(r => r.data);
        // Let's see if we should remove the avatar
        if (this.shouldRemoveExistingAvatar || this.fileList.length) {
          this.removeFromCache('presence-thumbnail-' + this.user.id);
          try {
            await presences.deletePresenceThumb(this.tenantId, this.user.id);
          } catch (e) {
            console.log(e);
            vm.$message.error("Error removing existing avatar");
          }
        }

        // Now let's try adding in a new avatar
        if (this.fileList.length) {
          try {
            await presences.addPresenceThumb(
              this.tenantId,
              this.user.id,
              this.fileList[0]
            );
          } catch (e) {
            console.log(e);
            vm.$message.error("Error updating avatar");
          }
        }
        vm.isSaving = false;
        vm.$message.success("User updated successfully");
        // vm.$router.push('/admin/users/manage/' + this.user.id);
        vm.$emit('user-updated', updatedPresence);
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error updating user");
      }
    },
  },
};
</script>

<style lang="scss">
.admin-edit-user {
  background: #fff;
  padding: 20px;
  border-radius: 6px;

  max-height: 500px;
  overflow-y: scroll;

  h1 {
    font-size: 17px !important;
    margin-bottom: 20px;
  }

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;

    .left {
      flex-shrink: 1;
    }

    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .has-custom-avatar {
    .ant-form-item-children {
      display: flex !important;
      flex-direction: horizontal;

      .custom-avatar {
        padding-right: 15px;
      }
    }
  }

  .form {
    // padding: 3px;
    background: #fbfbfb;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .custom-avatar {
    margin-bottom: 15px;
    display: flex;

    .left {
      flex-shrink: 1;
    }

    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>