<template>
  <div>
    <loading-screen :is-loading="isSaving" />

    <a-row :gutter="40">
      <a-col :span="12">
        <div class="explanation">
          <h2>Please Invite Your IT Manager</h2>
          <p>
            We're excited to help you streamline and facilitate a smooth integration of teamSOS at your school.

            To make this process effective, we encourage you to invite your IT manager as an administrator within teamSOS
            platform.
          </p>
          <p>
            Ideally, we need a technical contact to be added to ensure that teamSOS is integrated seamlessly with existing
            systems and networks and resolve any technical glitches you may experience to ensure that the teamSOS platform
            aligns seamlessly with your needs.
          </p>
          <p>
            We provide all the technical information in an email to your IT contact which will be launched in your email
            platform to inform your colleague to successfully deploy teamSOS to your organisation.
          </p>
          <p>
            Please enter your IT contacts details to add them into the teamSOS platform as an administrator.
          </p>
        </div>
      </a-col>
      <a-col :span="12">
        <div v-if="!isLoading && !presence" class="form">
          <h2>IT Manager Contact Details</h2>

          <a-form-item label="First Name">
            <a-input size="large" v-model="firstName" placeholder="First Name" />
          </a-form-item>

          <a-form-item label="Last Name">
            <a-input size="large" v-model="lastName" placeholder="Last Name" />
          </a-form-item>

          <a-form-item label="Email">
            <a-input size="large" v-model="email" placeholder="Email" />
          </a-form-item>

          <div class="actions">
            <a-button class="btn-rounded" type="primary" size="large" @click.prevent="attemptCreateUser">Invite to
              teamSOS</a-button>
          </div>

          <div>
            <a @click.prevent="skipStep" href="#">Are you the IT manager? Click here to skip!</a>
            <!-- <a-icon type="info-circle" /> We will send an email to your IT contact with instructions on how to deploy
              teamSOS to your organisation. -->
          </div>
        </div>

        <div v-if="isLoading" class="form" style="text-align: center;">
          <a-spin />
        </div>

        <div v-if="!isLoading && presence" class="form">
          <h2>IT Manager Contact Details</h2>
          <p>
            {{ presence.displayName }} has been appointed as your IT Manager.
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import LoadingScreen from '../LoadingScreen.vue';
import { mapGetters } from 'vuex';
import presences from '../../api/presences';
import organisations from '../../api/organisations';

// const NONE = 0;
const LOOKUP = 1;
const USER = LOOKUP | 2;
const ADMIN = USER | 4;
// const GUEST = LOOKUP | 8;

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',

      isLoading: false,
      isSaving: false,
      presence: null
    }
  },

  watch: {
    organisation() {
      this.loadItManagerIfExists();
    }
  },

  computed: {
    ...mapGetters('onboarding', {
      tenantId: 'tenantId',
      organisation: 'organisation'
    })
  },

  components: { LoadingScreen },

  created() {
    this.loadItManagerIfExists();
  },

  methods: {
    loadItManagerIfExists() {
      let vm = this;
      vm.presence = null;
      vm.isLoading = true;
      organisations.getMembershipForOrg(this.tenantId, this.organisation.id).then(r => {
        let itManagers = r.data.filter(m => m.isITManager);
        if (itManagers.length) {
          vm.presence = itManagers[0];
        }
        vm.isLoading = false;
      }).catch(e => {
        vm.isLoading = false;
        console.log(e);
      });
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    getValidationErrors() {
      let errors = [];
      if (this.firstName.trim().length == 0) {
        errors.push("Please provide a first name");
      }
      if (this.lastName.trim().length == 0) {
        errors.push("Please provide a surname");
      }
      if (this.email.trim().length == 0) {
        errors.push("Please provide an email");
      }
      if (!this.validateEmail(this.email)) {
        errors.push(this.email + " is not a valid email");
      }

      return errors;
    },

    attemptCreateUser() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;
      presences.addPresence(
        this.tenantId,
        {
          Presence: {
            firstname: this.firstName,
            surname: this.lastName,
            email: this.email,
            ownerId: this.organisation.id,
            isITManager: true,
            permissions: ADMIN
          },
          SendInviteEmail: true,
          MakeSosResponder: false
        }
      ).then(r => {
        vm.isSaving = false;
        vm.presence = r.data;
        vm.$emit('next-stage');
      }).catch(e => {
        vm.isSaving = false;
        vm.$message.error(e.response.data);
      });
    },

    skipStep() {
      this.$emit('next-stage');
    }
  }
}
</script>

<style scoped lang="scss">
.explanation {
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  // text-align: center;
  // min-height: 230px;

  p:last-child {
    margin-bottom: 0;
  }
}

.form {
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  // text-align: center;
  min-height: 230px;
}

.actions {
  margin-bottom: 25px;
  // text-align: right;
}
</style>