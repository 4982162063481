const NONE = 0;
const LOOKUP = 1;
const USER = LOOKUP | 2;
const ADMIN = USER | 4;
const GUEST = LOOKUP | 8;

export default {
    hasPermission(grantedPermissions, permissionLevelToCheck) {
        return (grantedPermissions & permissionLevelToCheck) == permissionLevelToCheck
    },

    getUserPermissionDescription(user) {
        let permissions = user.permissions ? user.permissions : 0;
        let isAdmin = this.hasPermission(permissions, ADMIN);
        if (isAdmin) {
            return 'Admin';
        }
        let isUser = this.hasPermission(permissions, USER);
        if (isUser) {
            return 'User';
        }
        let isGuest = this.hasPermission(permissions, GUEST);
        if (isGuest) {
            return 'Guest';
        }
        let isLookup = this.hasPermission(user.permissionLevelToCheck, LOOKUP);
        if (isLookup) {
            return 'Lookup';
        }
        if (permissions == NONE) {
            return 'Blocked';
        }
        return 'Unknown';
    }
}