<template>
  <div>
    <!-- Is Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- <a-alert style="margin-bottom: 25px;" message="Response Groups play a vital role in managing incidents effectively. To ensure incidents are addressed promptly, please add the users who will be responding to incidents by assigning the users to the appropriate response groups. When an incident is triggered, teamSOS will promptly notify the designated responders within the allocated group, based on the incident type. This ensures that responders are quickly informed of active incidents that require their assistance, facilitating a swift and effective response."></a-alert> -->

      <a-row :gutter="40" type="flex">
        <a-col :span="6">
          <response-groups-sidebar :response-groups="responseGroupsToShow"
            :response-group-membership-counts="responseGroupMembershipCounts"
            :selected-response-group="selectedResponseGroup" :key="`sidebar-update-${sidebarUpdateKey}`"
            @select-response-group="selectResponseGroup"></response-groups-sidebar>
        </a-col>
        <a-col :span="18">

          <create-response-group v-if="selectedResponseGroup == 'create'" :response-groups="responseGroups"
            :organisation="organisation" :tenant-id="tenantId" :key="createResponseGroupRefreshKey"
            @response-group-created="handleResponseGroupCreated">
          </create-response-group>

          <edit-response-group v-if="selectedResponseGroup && selectedResponseGroup !== 'create'" :tenant-id="tenantId"
            :organisation="organisation" :response-group-id="selectedResponseGroup.id" :membership="membership"
            @response-group-updated="updateResponseGroupInline"
            @response-group-deleted="loadResponseGroupsAndSelectFirst">
          </edit-response-group>

        </a-col>
      </a-row>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skills from '../../api/skills';
import CreateResponseGroup from './ResponseGroups/CreateResponseGroup.vue';
import EditResponseGroup from './ResponseGroups/EditResponseGroup.vue';
import ResponseGroupsSidebar from './ResponseGroups/ResponseGroupsSidebar.vue';
import _ from 'lodash';
import eventBus from '../../event-bus';

export default {
  components: { ResponseGroupsSidebar, CreateResponseGroup, EditResponseGroup },

  data() {
    return {
      isLoadingResponseGroups: false,
      responseGroups: [],

      isLoadingResponseGroupMembershipCounts: false,
      responseGroupMembershipCounts: {},
      sidebarUpdateKey: 1,

      selectedResponseGroup: null,

      createResponseGroupRefreshKey: 1
    }
  },

  watch: {
    organisation() {
      this.isLoadingResponseGroups = false;
      this.responseGroups = [];
      this.isLoadingResponseGroupMembershipCounts = false;
      this.responseGroupMembershipCounts = {};
      this.selectedResponseGroup = null;
      this.createResponseGroupRefreshKey = 1;
      this.loadResponseGroupsAndOpenCreateForm();
    }
  },

  created() {
    this.loadResponseGroupsAndOpenCreateForm();

    eventBus.$on('response-team-membership-loaded', (data) => {
      let { responseTeamId, membershipLength } = data;
      this.responseGroupMembershipCounts[responseTeamId] = membershipLength;
      this.sidebarUpdateKey++;
    });
  },

  computed: {
    ...mapGetters('onboarding', {
      tenantId: 'tenantId',
      organisation: 'organisation',
      membership: 'membership',
      isLoadingMembership: 'isLoadingMembership'
    }),

    isLoading() {
      return (
        this.isLoadingResponseGroups
        || this.isLoadingMembership
        || this.isLoadingResponseGroupMembershipCounts
      );
    },

    responseGroupsToShow() {
      return this.responseGroups;
    }
  },

  methods: {
    loadResponseGroups() {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.isLoadingResponseGroups = true;
        vm.responseGroups = [];
        skills
          .getSkills(this.tenantId, this.organisation.id, true)
          .then((r) => {
            vm.responseGroups = r.data;
            vm.isLoadingResponseGroups = false;
            vm.loadMembershipCounts();
            resolve(vm.responseGroups);
          })
          .catch((e) => {
            console.log(e);
            vm.isLoadingResponseGroups = false;
            vm.$message.error("Error loading response teams");
            reject(e);
          });
      });
    },

    async loadMembershipCounts() {
      let vm = this;
      vm.isLoadingResponseGroupMembershipCounts = true;
      vm.responseGroupMembershipCounts = {};
      for (let responseGroup of vm.responseGroups) {
        let responseGroupMembership = await skills.getPresencesForSkill(this.tenantId, this.organisation.id, responseGroup.id).then(r => r.data);
        vm.responseGroupMembershipCounts[responseGroup.id] = responseGroupMembership.length;
      }
      vm.isLoadingResponseGroupMembershipCounts = false;
      vm.sidebarUpdateKey++;
    },

    loadResponseGroupsAndSelectFirst() {
      let vm = this;
      vm.loadResponseGroups().then(() => {
        if (vm.responseGroups.length) {
          vm.selectedResponseGroup = _.first(vm.responseGroupsToShow);
        }
      }).catch(e => {
        console.log(e);
      });
    },

    selectResponseGroup(responseGroup) {
      let vm = this;
      vm.selectedResponseGroup = responseGroup;
    },

    loadResponseGroupsAndOpenCreateForm() {
      let vm = this;
      vm.createResponseGroupRefreshKey += 1;
      vm.loadResponseGroups().then(() => {
        vm.selectedResponseGroup = 'create';
      }).catch(e => {
        console.log(e);
      });
    },

    handleResponseGroupCreated() {
      this.loadResponseGroupsAndOpenCreateForm();
    },

    loadResponseGroupsAndResetCreateForm() {
      let vm = this;
      vm.createResponseGroupRefreshKey += 1;
      vm.loadResponseGroups().then(() => {
        if (vm.responseGroups.length) {
          vm.selectedResponseGroup = _.first(vm.responseGroupsToShow);
        }
      }).catch(e => {
        console.log(e);
      });
    },

    updateResponseGroupInline(responseGroup) {
      let vm = this;
      let index = _.findIndex(vm.responseGroups, { id: responseGroup.id });
      if (index > -1) {
        vm.responseGroups.splice(index, 1, responseGroup);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  padding-top: 20px;
  text-align: center;
}
</style>