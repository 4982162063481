<template>
  <div class="user-sidebar">

    <div class="user" @click.prevent="selectUser('create')"
          :class="{ 'selected': ('create' == selectedUserId) }">
          <span class="display-name">+ Add New User</span>
      </div>

      <div class="user" @click.prevent="selectUser(user)"
          :class="{ 'selected': (user.id == selectedUserId) }"
          v-for="(user, i) in usersToShow" :key="`user-${i}`">
          <div class="left">
            <span class="display-name">{{ user.displayName }}</span>
          </div>
          <div class="right">
            <a-badge v-if="isUserAdmin(user)" count="Admin" />
          </div>
      </div>

      <!-- No users -->
      <div v-if="!usersToShow.length" class="user no-hover">
          <span class="display-name">No Users</span>
      </div>


  </div>
</template>

<script>
import userHelpers from '../../../helpers/users';
export default {
  props: ['users', 'selectedUser'],

  methods: {
      selectUser(user) {
          this.$emit('select-user', user);
      },

      isUserAdmin(user) {
        return userHelpers.getUserPermissionDescription(user) == 'Admin';
      }
  },

  computed: {
      selectedUserId() {
          if (this.selectedUser && this.selectedUser.id) {
              return this.selectedUser.id;
          }

          if (this.selectedUser == 'create') {
              return 'create';
          }

          return null;
      },

     usersToShow() {
          return this.users;
      }
  }
}
</script>

<style scoped lang="scss">
.user-sidebar {
  background: #fff;
  padding: 5px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;

  max-height: 500px;
  overflow-y: scroll;

  .user {
      padding: 13px;
      border-radius: 5px;
      margin-bottom: 5px;
      border: 1px solid #fff;

      display: flex;

        .left {
            flex-grow: 1;
        }

        .right {
            flex-shrink: 1;
            text-align: right;
            display: flex;
            align-items: center;
        }

      &:not(.no-hover):hover {
          color: #5155ea;
          border: 1px solid #5155ea;
          cursor: pointer;
      }

      &.selected {
          // background: #5155ea;
          background: rgba(81, 85, 234, 0.1);
          // border: 1px solid rgba(81, 85, 234, 0.1) !important;
          // color: #fff;
          // border: 1px solid #5155ea; 
      }
  }
}
</style>