<template>
    <div>
        <loading-screen :is-loading="isSaving"></loading-screen>

        <div class="onboarding-review-inner">

            <!-- Users-->
            <div class="review-card" @click.prevent="setStage(2)">
                <div class="left">
                    <h3>Users</h3>
                </div>
                <div class="right">
                    <!-- Is Loading Users -->
                    <a-spin size="small" v-if="isLoadingUsers" />
                    <!-- / Is Loading Users -->

                    <!-- Check If Users -->
                    <a-icon type="check-circle" class="check-icon" v-if="!isLoadingUsers && hasUsers" />
                    <!-- / Check If Users -->

                    <!-- Cross If No Users -->
                    <a-icon type="exclamation-circle" class="alarm-icon" v-if="!isLoadingUsers && !hasUsers" />
                    <!-- / Cross If No Users -->
                </div>
            </div>
            <!-- / Users-->

            <!-- Response Groups -->
            <div class="review-card" @click.prevent="setStage(3)">
                <div class="left">
                    <h3>Response Groups</h3>
                </div>
                <div class="right">
                    <!-- Is Loading Response Groups -->
                    <a-spin size="small" v-if="(isLoadingResponseGroups || isLoadingResponseGroupMembershipCounts)" />
                    <!-- / Is Loading Response Groups -->

                    <!-- Check If Response Groups -->
                    <a-icon type="check-circle" class="check-icon" v-if="!(isLoadingResponseGroups || isLoadingResponseGroupMembershipCounts) && responseGroupsValid" />
                    <!-- / Check If Response Groups -->

                    <!-- Cross If No Response Groups -->
                    <a-icon type="exclamation-circle" class="alarm-icon"
                        v-if="!(isLoadingResponseGroups||isLoadingResponseGroupMembershipCounts) && !responseGroupsValid" />
                    <!-- / Cross If No Response Groups -->
                </div>
            </div>
            <!-- / Response Groups-->

            <!-- Emergency Types -->
            <div class="review-card" @click.prevent="setStage(4)">
                <div class="left">
                    <h3>Emergency Types</h3>
                </div>
                <div class="right">
                    <!-- Is Loading -->
                    <a-spin size="small" v-if="isLoadingIncidentTypes" />
                    <!-- / Is Loading -->

                    <a-icon type="check-circle" class="check-icon" v-if="!isLoadingIncidentTypes && hasEmergencyTypes" />

                    <a-icon type="exclamation-circle" class="alarm-icon"
                        v-if="!isLoadingIncidentTypes && !hasEmergencyTypes" />
                </div>
            </div>
            <!-- / Emergency Types-->

            <!-- Incident Types -->
            <div class="review-card" @click.prevent="setStage(5)">
                <div class="left">
                    <h3>Incident Types</h3>
                </div>
                <div class="right">
                    <!-- Is Loading -->
                    <a-spin size="small" v-if="isLoadingIncidentTypes" />
                    <!-- / Is Loading -->

                    <a-icon type="check-circle" class="check-icon" v-if="!isLoadingIncidentTypes && hasIncidentTypes" />

                    <a-icon type="exclamation-circle" class="alarm-icon"
                        v-if="!isLoadingIncidentTypes && !hasIncidentTypes" />
                </div>
            </div>
            <!-- / Incident Types-->

            <!-- Submit -->
            <a-button :disabled="!hasAllRequiredData" @click.prevent="markOnboardingAsComplete" type="primary" size="large"
                class="btn-rounded finish-onboarding-button">Click Here To Finish Onboarding</a-button>
            <!-- / Submit -->

        </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex';
import organisations from '../../api/organisations';
import skills from '../../api/skills';
import incidentTypes from '../../api/incident-types';
import _ from 'lodash';
import LoadingScreen from '../LoadingScreen.vue';
import onboarding from '../../api/onboarding';

export default {
    components: { LoadingScreen },
    data() {
        return {
            isSaving: false,

            isLoadingUsers: false,
            users: [],

            isLoadingResponseGroups: false,
            responseGroups: [],

            isLoadingResponseGroupMembershipCounts: false,
            responseGroupMembershipCounts: {},
            idsOfResponseGroupsWithNoPresences: [],

            isLoadingIncidentTypes: false,
            incidentTypes: []
        }
    },

    computed: {
        ...mapGetters('onboarding', {
            tenantId: 'tenantId',
            organisation: 'organisation'
        }),

        isLoading() {
            return this.isLoadingUsers || this.isLoadingResponseGroups || this.isLoadingResponseGroupMembershipCounts || this.isLoadingIncidentTypes;
        },

        hasUsers() {
            return this.users.length > 0;
        },

        hasResponseGroups() {
            return this.responseGroups.length > 0;
        },

        doAllResponseGroupsHaveMembership() {
            let vm = this;

            return vm.idsOfResponseGroupsWithNoPresences.length === 0;

            // let doAllResponseGroupsHaveMembership = true;

            // for (let responseGroup of vm.responseGroups) {
            //     if (vm.responseGroupMembershipCounts[responseGroup.id] === undefined || vm.responseGroupMembershipCounts[responseGroup.id] === 0) {
            //         doAllResponseGroupsHaveMembership = false;
            //     }
            // }

            // return doAllResponseGroupsHaveMembership;
        },

        responseGroupsValid() {
            return this.hasResponseGroups && this.doAllResponseGroupsHaveMembership;
        },

        hasIncidentTypes() {
            return _.filter(this.incidentTypes, (incidentType) => {
                return incidentType.purpose !== 1;
            }).length > 0;
        },

        hasEmergencyTypes() {
            return _.filter(this.incidentTypes, (incidentType) => {
                return incidentType.purpose === 1;
            }).length > 0;
        },

        hasAllRequiredData() {
            return this.hasUsers && this.responseGroupsValid && this.hasIncidentTypes && this.hasEmergencyTypes && !this.isLoading;
        }
    },

    created() {
        this.loadUsers();
        this.loadResponseGroups();
        this.loadIncidentTypes();
    },

    methods: {
        ...mapActions('missionControl', {
            updateOrganisationInline: 'updateOrganisationInline'
        }),

        ...mapActions('onboarding', {
            hideOrganisation: 'hideOrganisation',
            setStage: 'setStage'
        }),

        loadUsers() {
            let vm = this;
            vm.isLoadingUsers = true;
            return organisations.getMembershipForOrg(vm.tenantId, vm.organisation.id).then(response => {
                vm.users = response.data;
                vm.isLoadingUsers = false;
            }).catch(e => {
                vm.isLoadingUsers = false;
                console.log(e);
            });
        },

        loadResponseGroups() {
            let vm = this;
            vm.isLoadingResponseGroups = true;
            vm.isLoadingResponseGroupMembershipCounts = true;
            skills.getSkills(this.tenantId, this.organisation.id, true).then(r => {
                vm.responseGroups = r.data;
                vm.isLoadingResponseGroups = false;
                vm.loadMembershipCounts();
            }).catch(e => {
                console.log(e);
                vm.isLoadingResponseGroups = false;
            });
        },

        async loadMembershipCounts() {
            let vm = this;
            vm.isLoadingResponseGroupMembershipCounts = true;
            vm.responseGroupMembershipCounts = {};
            vm.idsOfResponseGroupsWithNoPresences = [];
            for (let responseGroup of vm.responseGroups) {
                let responseGroupMembership = await skills.getPresencesForSkill(this.tenantId, this.organisation.id, responseGroup.id).then(r => r.data);
                if (responseGroupMembership.length === 0) {
                    vm.idsOfResponseGroupsWithNoPresences.push(responseGroup.id);
                }
                // vm.responseGroupMembershipCounts[responseGroup.id] = responseGroupMembership.length;
            }
            vm.isLoadingResponseGroupMembershipCounts = false;
        },

        loadIncidentTypes() {
            let vm = this;
            vm.isLoadingIncidentTypes = true;
            incidentTypes.getIncidentTypes(this.tenantId, this.organisation.id).then(r => {
                vm.incidentTypes = _.filter(r.data, (incidentType) => {
                    return incidentType.ownerId == vm.organisation.id;
                })
                vm.isLoadingIncidentTypes = false;
            }).catch(e => {
                console.log(e);
                vm.isLoadingIncidentTypes = false;
            });
        },

        markOnboardingAsComplete() {
            let vm = this;

            if (vm.isSaving) {
                return false;
            }

            vm.isSaving = true;
            onboarding.setOnboardingStage(this.organisation.id, 'Complete').then(r => {
                vm.isSaving = false;
                vm.updateOrganisationInline(r.data);
                vm.hideOrganisation(r.data.id);
                vm.$router.push('/dashboard');
            }).catch(e => {
                vm.isSaving = false;
                vm.$message.error('Error marking onboarding as complete.');
                console.log(e);
            });
        }
    }
}
</script>
  
<style scoped lang="scss">
.onboarding-review-inner {
    padding-left: 20px;
    padding-right: 20px;

    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .review-card {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 18px 20px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        }

        h3 {
            margin: 0;
            padding: 0;
            font-size: 18px;
        }

        display: flex;

        .left {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }

        .right {
            flex-shrink: 1;
            display: flex;
            align-items: center;

            .ant-spin {
                margin-top: 4px;
            }

            .check-icon {
                color: #34b233;
                font-size: 23px;
            }

            .alarm-icon {
                color: #f5222d;
                font-size: 23px;
            }
        }
    }

    .finish-onboarding-button {
        width: 100%;
        height: 50px;
    }
}
</style>