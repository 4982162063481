import { render, staticRenderFns } from "./EmergencyTypesSidebar.vue?vue&type=template&id=7b671148&scoped=true&"
import script from "./EmergencyTypesSidebar.vue?vue&type=script&lang=js&"
export * from "./EmergencyTypesSidebar.vue?vue&type=script&lang=js&"
import style0 from "./EmergencyTypesSidebar.vue?vue&type=style&index=0&id=7b671148&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b671148",
  null
  
)

export default component.exports