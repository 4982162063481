<template>
  <div>
    <!-- Is Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <a-row :gutter="40" type="flex">
        <a-col :span="6">
          <emergency-types-sidebar :incident-types="incidentTypesToShow" :skills="skills"
            :selected-incident-type="selectedIncidentType"
            @select-incident-type="selectIncidentType"></emergency-types-sidebar>
        </a-col>
        <a-col :span="18">

          <create-emergency-type :incident-types="incidentTypes" :skills="skills" :organisation="organisation"
            :tenant-id="tenantId" :key="createEmergencyTypeRefreshKey"
            @emergency-type-created="loadIncidentTypesAndResetCreateForm"
            v-if="selectedIncidentType == 'create'"></create-emergency-type>

          <edit-emergency-type v-if="selectedIncidentType && selectedIncidentType !== 'create'" :tenant-id="tenantId"
            :organisation="organisation" :skills="skills" :incident-type-id="selectedIncidentType.id"
            @emergency-type-updated="updateIncidentTypeInline" @emergency-type-deleted="loadIncidentTypesAndResetCreateForm">
          </edit-emergency-type>

        </a-col>
      </a-row>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import incidentTypes from '../../api/incident-types';
import organisations from '../../api/organisations';
import EmergencyTypesSidebar from './EmergencyTypes/EmergencyTypesSidebar.vue';
import CreateEmergencyType from './EmergencyTypes/CreateEmergencyType.vue';
import EditEmergencyType from './EmergencyTypes/EditEmergencyType.vue';

export default {
  data() {
    return {
      isLoadingIncidentTypes: false,
      incidentTypes: [],

      isLoadingSkills: false,
      skills: [],

      selectedIncidentType: null,

      createEmergencyTypeRefreshKey: 1
    }
  },

  components: {
    EmergencyTypesSidebar,
    CreateEmergencyType,
    EditEmergencyType
  },

  watch: {
    organisation() {
      this.isLoadingIncidentTypes = false;
      this.incidentTypes = [];
      this.selectedIncidentType = null;
      this.createEmergencyTypeRefreshKey = 1;
      this.loadSkills();
      this.loadIncidentTypesAndResetCreateForm();
    }
  },

  created() {
    this.loadSkills();
    // this.loadIncidentTypes();
    this.loadIncidentTypesAndResetCreateForm();
  },

  computed: {
    ...mapGetters('onboarding', {
      tenantId: 'tenantId',
      organisation: 'organisation'
    }),

    isLoading() {
      return this.isLoadingIncidentTypes || this.isLoadingSkills;
    },

    incidentTypesToShow() {
      let filtered = this.incidentTypes;
      let vm = this;

      // Only show sos  (type == 1)
      filtered = _.filter(filtered, (incidentType) => {
        return incidentType.purpose == 1;
      });

      return _.filter(filtered, (incidentType) => {
        return incidentType.ownerId == vm.organisation.id;
      });
    }
  },

  methods: {
    loadSkills() {
      let vm = this;
      vm.skills = [];
      vm.isLoadingSkills = true;
      organisations.getSkillsForOrg(this.tenantId, this.organisation.id).then(r => {
        vm.skills = r.data;
        vm.isLoadingSkills = false;
      }).catch(e => {
        console.log(e);
        vm.isLoadingSkills = false;
        vm.$message.error("Error loading skills");
      });
    },

    loadIncidentTypes() {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.incidentTypes = [];
        vm.isLoadingIncidentTypes = true;
        incidentTypes.getIncidentTypes(
          this.tenantId,
          this.organisation.id
        ).then((response) => {
          vm.incidentTypes = response.data;
          vm.isLoadingIncidentTypes = false;
          resolve(vm.incidentTypes);
        }).catch((error) => {
          vm.isLoadingIncidentTypes = false;
          vm.$message.error(error.response.data.message);
          reject(error);
        });
      });
    },

    selectIncidentType(incidentType) {
      let vm = this;
      vm.selectedIncidentType = incidentType;
    },

    loadIncidentTypesAndResetCreateForm() {
      let vm = this;
      vm.createEmergencyTypeRefreshKey += 1;
      vm.loadIncidentTypes().then(() => {
        // if (vm.incidentTypes.length) {
        //   vm.selectedIncidentType = _.first(vm.incidentTypesToShow);
        // }
        vm.selectedIncidentType = 'create';
      }).catch(e => {
        console.log(e);
      });
    },

    loadIncidentTypesAndSelectFirst() {
      let vm = this;
      vm.loadIncidentTypes().then(() => {
        if (vm.incidentTypes.length) {
          vm.selectedIncidentType = _.first(vm.incidentTypesToShow);
        }
      }).catch(e => {
        console.log(e);
      });
    },

    updateIncidentTypeInline(incidentType) {
      let vm = this;
      let index = _.findIndex(vm.incidentTypes, { id: incidentType.id });
      if (index > -1) {
        vm.incidentTypes.splice(index, 1, incidentType);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  padding-top: 20px;
  text-align: center;
}
</style>