<template>
  <div>
    <a-button type="primary" icon="plus" @click.prevent="openModal">Add Team Members</a-button>

    <a-modal centered v-model="showModal" title="Add Team Members" :footer="null">
      <!-- Header -->
      <div class="header">
        <div class="left">
          You have selected {{ selectedPresenceIds.length }} {{ selectedPresenceIds.length == 1 ? 'user' : 'users' }}
        </div>
        <div class="right">
          <a-button type="primary" @click.prevent="addPresences" :disabled="selectedPresenceIds.length == 0">Add To Team
          </a-button>
        </div>
      </div>
      <!-- / Header -->

      <!-- Search -->
      <div class="top-row">
        <div class="left">
        <a-input-search placeholder="Search..." style="width: 230px" v-model="searchQuery" />
        </div>
        <div class="right">
         <a-button @click.prevent="deselectAll" style="margin-right: 10px;">Deselect All</a-button> 
         <a-button @click.prevent="selectAll">Select All</a-button> 
        </div>
      </div>
      <!-- / Search -->

      <a-alert v-if="presencesToShow.length == 0" type="info" message="No team members to show"></a-alert>

      <div class="presences-wrapper">
        <div @click.prevent="() => togglePresenceSelected(presence)"
          :class="{'selected': isPresenceSelected(presence)}"
           class="presence-list-item"
          v-for="presence in presencesToShow" :key="presence.id">
          <div class="left">
            <presence-thumbnail
            :owner-id="orgId"
            :presence-id="presence.id"
            ></presence-thumbnail>
          </div>
          <div class="middle">
            {{ presence.displayName }}
          </div>
          <div class="right">
            <a-icon v-if="isPresenceSelected(presence)" theme="twoTone" two-tone-color="#52c41a" type="check-circle" />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const _ = require("lodash");
import PresenceThumbnail from "../../../../../../components/Presences/PresenceThumbnail.vue";
export default {
  props: ["tenantId", "orgId", "responseTeam", "membership", "allPresences"],
  components: {PresenceThumbnail},
  data() {
    return {
      showModal: false,
      searchQuery: "",

      selectedPresenceIds: []
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    deselectAll() {
      this.selectedPresenceIds = [];
    },

    selectAll() {
      this.selectedPresenceIds = _.map(this.presencesToShow, 'id');
    },

    addPresence(presence) {
      this.closeModal();
      this.$emit("add-presence", presence.id);
    },

    addPresences() {
      this.closeModal();
      this.$emit('add-presences', this.selectedPresenceIds);
    },

    togglePresenceSelected(presence) {
      this.selectedPresenceIds = _.xor(this.selectedPresenceIds, [presence.id]);
    },

    isPresenceSelected(presence) {
      return this.selectedPresenceIds.includes(presence.id);
    }
  },
  computed: {
    presencesToShow() {
      let selectedIds = _.map(this.membership, "id");
      let notAdded = _.filter(this.allPresences, (presence) => {
        return !selectedIds.includes(presence.id);
      });

      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(notAdded, (member) => {
          return member.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return _.orderBy(notAdded, i => i.displayName.toLowerCase()[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.top-row {
  margin-bottom: 20px;
  // text-align: right;
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}

.presences-wrapper {
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 10px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.presence-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 8px 9px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #f9f9f9;
  }

  &.selected {
    background: #eee !important;
  }

  .left {
    flex-shrink: 1;
  }

  .middle {
    flex-grow: 1;
    padding-left: 15px;
  }

  .right {
    flex-shrink: 1;

    .anticon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}

.header {
  margin-bottom: 20px;
  background: #f7f8fa;
  border-radius: 10px;
  padding: 10px;
  display: flex;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .right {
    flex-shrink: 1;
    text-align: right;
  }
}
</style>